import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import DataTableHistoryService from 'services/DataTableHistoryService';

export const initialState = {
    objectListData: {
        objects: [],
        isLoading: true,
        isLoaded: false,
    },
    relationData: {

    },
    selectedObjectData: {
        object: null,
        isLoading: false,
        isLoaded: false,
    }
}


export const getObjects = createAsyncThunk('dataTable/getObjects', async ({id, params, recordId}, { rejectWithValue }) => {
    try {
        const response = await DataTableHistoryService.getObjects(id, params, recordId);
        return response
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})
export const restoreObject = createAsyncThunk('dataTable/restoreObject', async ({id, historyId}, { rejectWithValue }) => {
    try {
        const response = await DataTableHistoryService.restore(id, historyId);
        return {object: response?.result, historyId}
    } catch (err) {
        return rejectWithValue({ message: err.message || 'Error', errors: err?.response?.data?.errors })
    }
})

export const objectSlice = createSlice({
    name: 'dataTableHistory',
    initialState,
    reducers: {
        setSelectedObjectData: (state, action) => {
            const { object } = action?.payload;
            state.selectedObjectData = { ...state.selectedObjectData, object, isLoaded: true, isLoading: false }
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getObjects.fulfilled, (state, action) => {
                const { objects, page, totalPages, totalRecords, limit } = action?.payload?.result;
                state.objectListData = { ...state.objectListData, objects, page, totalPages, totalRecords, limit, isLoaded: true, isLoading: false }
            })
            .addCase(getObjects.pending, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: false, isLoading: true }
            })
            .addCase(getObjects.rejected, (state) => {
                state.objectListData = { ...state.objectListData, isLoaded: true, isLoading: false }
            })
            .addCase(restoreObject.fulfilled, (state, action) => {
                const { historyId, object } = action?.payload;
                const objects = state.objectListData.objects.filter(object => object?._id !== historyId);
                objects.unshift(object);
                state.objectListData = { ...state.objectListData,objects, isLoaded: true, isLoading: false }
            })
    },
})

export const {
    setSelectedObjectData
} = objectSlice.actions

export default objectSlice.reducer